import React from "react"
import YouTube from "react-youtube"
import {
  useWindowWidth,
} from '@react-hook/window-size'

export default ({ videoVisible, setVideoVisible }) => {
  if (!videoVisible) return null

  const width = useWindowWidth()

  const videoRatio = 0.5625
  
  let videoWidth = 1000
  if (width < 1200) {
    videoWidth = width * .9
  }
  
  const videoHeight = videoWidth * videoRatio

  console.log('videoWidth', videoWidth);
  console.log('videoHeight', videoHeight);
  

  const opts = {
    height: videoHeight,
    width: videoWidth,
    playerVars: {
      autoplay: 1,
    },
  }

  return (
    <div className="overlay">
      <button
        className="close bg-red hover:bg-red-700 text-white inline-block font-sansBold py-4 px-6 rounded-full"
        onClick={e => setVideoVisible(false)}
      >
        Close
      </button>
      <YouTube videoId="-Grbg9oLeZo" opts={opts} />
    </div>
  )
}
